@use "sass:math"; @import "abstract";
.requestFormWrapper {
  column-gap: 16px;
  display: flex;
  flex-basis: 428px;
  flex-direction: column;

  &__cultureName {
    margin-bottom: 16px;
  }

  &__unitLabel {
    white-space: nowrap;
  }

  &__inputs {
    display: grid;
    gap: 16px;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 40px;

    & > div {

      &:not(:nth-last-child(-n + 2)) {
        grid-column: span 2;
      }
    }
  }

  &__footer {
    margin-top: auto;
  }

  &__footerNotice {
    display: flex;
    margin-bottom: 16px;
  }
}

@include mq($until: desktop) {

  .requestFormWrapper {
    flex-basis: unset;
    width: 100%;

    &__footerSubmitButton {
      width: 100%;
    }

    &__inputs {

      & > div {
        grid-column: span 2;
      }
    }
  }
}

@include mq($until: tablet) {

  .requestFormWrapper {

    &__inputs {
      margin-bottom: 32px;
    }

    &__footer {
      margin-top: 0;
    }
  }
}
