@use "sass:math"; @import "abstract";
.newsCard {
  display: flex;

  &__img {
    height: 80px;
    margin-right: 16px;
    object-fit: cover;
    width: 80px;
  }

  &__date {
    color: $color-text-tertiary;
  }
}

@include mq($until: tablet) {

  .newsCard {

    &__img {
      height: 48px;
      width: 52px;
    }
  }
}
