@use "sass:math"; @import "abstract";
.bestOffersOutputs {
  padding-bottom: 40px;
  padding-top: 40px;

  &__header {
    margin-bottom: 24px;
  }
}

@include mq($until: tablet) {

  .bestOffersOutputs {
    padding-bottom: 0;
    padding-top: 32px;

    &__header {
      margin-bottom: 8px;
    }
  }
}
