@use "sass:math"; @import "abstract";
.catalogPromo {

  &__slide {
    aspect-ratio: 103 / 65;
    min-height: 260px;
    min-width: 412px;
  }
}

@include mq($until: tablet) {

  .catalogPromo {

    &__slide {
      aspect-ratio: 82 / 55;
      min-height: 220px;
      min-width: 328px;
    }
  }
}
