@use "sass:math"; @import "abstract";
.indexBanner {
  background-position: center;
  background-size: cover;
  display: block;
  height: 100%;
  width: 100%;

  &::before {
    background-color: $color-base-inverted;
    content: '';
    height: 100%;
    opacity: 0.65;
    position: absolute;
    width: 100%;
  }

  &__title {
    color: $color-text-inverted;
    margin-bottom: 32px;
  }

  &__content {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    padding-left: 48px;
    padding-top: 40px;
  }

  &__title {
    margin-bottom: 24px;
    z-index: 1;
  }
}

@include mq($until: tablet) {

  .indexBanners {

    &__title {
      margin-bottom: 16px;
    }

    &__content {
      padding-left: 24px;
      padding-top: 16px;
    }
  }
}
