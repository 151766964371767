@use "sass:math"; @import "abstract";
.loanCard {
  background-color: $color-status-info-neutral-fill;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 280px;
  padding: 16px 24px 24px;
  position: relative;
  transition: box-shadow 0.25s cubic-bezier(0.4, 0, 0.6, 1), background-color 0.25s cubic-bezier(0.4, 0, 0.6, 1);
  width: 305px;
  will-change: box-shadow, background-color;

  &:hover {
    background-color: $color-base-primary;
    box-shadow: 0 0 20px 0 rgba(46, 32, 50, 0.1);
  }

  &__linkOverlay {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  &__image {
    height: 80px;
    object-fit: cover;
    width: 80px;
  }

  &__title {
    align-items: flex-end;
    display: flex;
    height: 100%;
    justify-content: space-between;
    width: 100%;
  }

  &__icon {
    align-self: flex-end;
  }

  &__divider {
    background-color: $color-control-border-secondary;
    height: 1px;
    width: 100%;
  }

  &__benefitsList {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }

  &__benefitsItem {

    &::before {
      background-color: $color-control-bg-accent;
      border-radius: 50%;
      content: '';
      display: inline-block;
      height: 7px;
      margin-right: 8px;
      vertical-align: middle;
      width: 7px;
    }
  }
}

@include mq($until: tablet) {

  .loanCard {
    flex: 1 0 265px;
    gap: 12px;
    height: 265px;
  }
}
