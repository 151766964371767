@use "sass:math"; @import "abstract";
.aboutMarketplace {
  padding-bottom: 80px;
  padding-top: 60px;

  &__services {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: center;
  }

  &__header {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 56px;
  }

  &__title {
    margin-bottom: 10px;
    padding-bottom: 10px;
    padding-top: 10px;
  }

  &__advantages {
    display: flex;
    gap: 40px;
  }

  &__advantageItem {
    align-items: center;
    display: flex;
    gap: 16px;
    padding: 16px 8px;

    &Image {
      height: 24px;
      object-fit: contain;
      width: 24px;
    }
  }
}

// @include mq($until: desktop) {

//   .aboutMarketplace {

//     &__services {
//       flex-wrap: wrap;
//       justify-content: center;
//     }
//   }
// }

@include mq($until: tablet) {

  .aboutMarketplace {
    padding: 40px 0;

    &__services {
      display: grid;
      grid-auto-rows: 1fr;
      // flex-direction: column;
      // flex-wrap: nowrap;

      justify-content: unset;
    }

    &__title {
      margin-bottom: 24px;
    }

    &__advantages {
      flex-direction: column;
      gap: 16px;
    }

    &__advantageItem {
      padding: 0;
    }

    &__header {
      align-items: flex-start;
      margin-bottom: 40px;
    }
  }
}
