@use "sass:math"; @import "abstract";
$shadowOffset: 10px;

.manufacturers {

  &__header {
    margin-bottom: calc(32px - $shadowOffset);
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: center;
  }
}

@include mq($until: tablet) {

  .manufacturers {

    &__list {
      display: grid;
      grid-gap: 12px;
      grid-template-columns: repeat(8, 1fr);
      justify-content: flex-start;
      overflow: scroll;
      padding: $shadowOffset;
    }
  }
}
