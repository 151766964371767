@use "sass:math"; @import "abstract";
.indexBanners {
  max-width: $pageContentMaxWidth;
  overflow: hidden;
  padding-top: 4px;
  position: relative;
  width: 100%;

  &__slider {
    display: flex;
    height: 100%;
  }

  &__paginationDots {
    bottom: 24px;
    left: 40px;
    position: absolute;
    z-index: 2;
  }

  &__controlArrowItem {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;

    &--left {
      left: 0;
    }

    &--right {
      right: 0;
    }
  }
}

@include mq($until: desktop) {

  .indexBanners {
    padding-left: 0;
    padding-right: 0;

    &__controlArrowItem {
      display: none;
    }
  }
}
