@use "sass:math"; @import "abstract";
.bestOffersInputs {
  padding: 40px 0;

  &__header {
    margin-bottom: 24px;
  }
}

@include mq($until: tablet) {

  .bestOffersInputs {
    padding-bottom: 0;
    padding-top: 12px;
  }
}
