@use "sass:math"; @import "abstract";
.manufacturerCard {
  align-items: center;
  box-shadow: 0 0 20px 0 #2e20321a;
  display: flex;
  height: 120px;
  justify-content: center;
  width: 140px;

  &__img {
    height: 88px;
    object-fit: contain;
    width: 108px;
  }
}

@include mq($until: tablet) {

  .manufacturerCard {
    flex-shrink: 0;
    height: 80px;
    width: 108px;

    &__img {
      height: 56px;
      width: 76px;
    }
  }
}
