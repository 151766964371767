@use "sass:math"; @import "abstract";
.indexPageSection {
  padding-bottom: 40px;
  padding-top: 40px;

  &:not(:has(> *)) {
    display: none;
  }

  &--white {
    background-color: $color-base-primary;
  }

  &--grey {
    background-color: $color-base-secondary;
  }
}
