@use "sass:math"; @import "abstract";
.promoSlider {
  position: relative;

  &__image {
    height: 100%;
    object-fit: contain;
    width: 100%;
  }

  &__controlArrowItem {
    box-shadow: 0 0 20px 0 rgba(46, 32, 50, 0.1);
    position: absolute;
    top: 50%;
    z-index: 5;

    &--right {
      right: 0;
      transform: translate(50%, -50%);
    }

    &--left {
      left: 0;
      transform: translate(-50%, -50%);
    }

    &--hidden {
      display: none;
    }
  }

  &__link {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  &__paginationDots {
    bottom: 24px;
    left: 40px;
    position: absolute;
    z-index: 1;
  }

  &__swiperContainer {
    overflow: hidden;
  }
}

@include mq($until: tablet) {

  .promoSlider {

    &__slide {
      height: 100%;
      width: 100%;
    }

    &__controlArrowItem {
      display: none;
    }
  }
}
