@use "sass:math"; @import "abstract";
.popularQueries {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  &__label {
    color: $color-text-secondary;
    white-space: nowrap;
  }

  &__queryButton {
    border: 2px solid $color-control-border-primary;
    border-radius: 56px;
    height: 32px;
    padding-left: 16px;
    padding-right: 16px;
    white-space: nowrap;
  }
}

@include mq($until: desktop) {

  .popularQueries {
    flex-wrap: nowrap;
    gap: 8px;
    overflow-x: scroll;
    overflow-y: hidden;
    scrollbar-color: transparent transparent;
    scrollbar-width: thin;

    &:hover {
      scrollbar-color: initial;
    }

    &__label {
      display: none;
    }
  }
}

@include mq($until: tablet) {

  .popularQueries {
    gap: 8px;

    &__label {
      display: none;
    }
  }
}
