@use "sass:math"; @import "abstract";
.outputsCard {
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
  padding: 16px;
  position: relative;
  width: 100%;

  &__imageBlock {
    display: flex;
    flex-direction: column;
    position: relative;

    &--fixedHeight {
      min-height: 30px;
    }
  }

  &__label {

    &-imageOverlay {
      left: 8px;
      position: absolute;
      top: 8px;
    }
  }

  &__qualityIndicators {

    span {
      font-weight: unset;
    }
  }

  &__buyer {
    margin-bottom: auto;
  }

  &__lotPrices {
    gap: 8px 38px;
  }

  &__footer {
    display: flex;
    gap: 16px;
  }

  &__price {

    * {
      font-size: unset;
    }
  }

  &__priceDetails {
    color: $color-text-secondary;
    white-space: pre-line;
  }
}
