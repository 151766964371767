@use "sass:math"; @import "abstract";
.newsSection {
  padding-bottom: 40px;
  padding-top: 40px;

  &__list {
    display: grid;
    gap: 32px;
    grid-template-columns: repeat(2, 1fr);
  }
}

@include mq($until: tablet) {

  .newsSection {
    grid-template-columns: repeat(1, 1fr);
    padding-bottom: 20px;
    padding-top: 20px;

    &__list {
      gap: 16px;
      grid-template-columns: repeat(1, 1fr);
    }
  }
}

@include mq($until: desktop) {

  .newsSection {

    &__list {
      column-gap: 16px;
    }
  }
}
