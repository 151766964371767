@use "sass:math"; @import "abstract";
$cardhorizontalPadding: 32px;
$cardWidth: 286px;
$cardExpandedWidth: 1090px;

.cultureSaleCard {
  background-color: unset;
  border: 0;
  box-shadow: none;
  transition: width 0.3s ease;
  width: 286px;
  width: 100%;
  will-change: width;

  &:hover {
    border: 0;
    box-shadow: none;
  }

  &__card {
    background-color: $color-control-bg-secondary;
    background-image: url('/assets/images/index/outputs/cultureSale-bg.svg');
    background-position: bottom left;
    background-repeat: no-repeat;
    background-size: $cardExpandedWidth;
    background-size: 100%;
    display: flex;
    gap: 32px;
    height: 100%;
    padding: 32px $cardhorizontalPadding 16px;
    width: 100%;

    // &--fullWidth {
    // }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
  }

  &__footer {
    display: flex;
  }

  &__button {
    transition: transform 0.3s ease, opacity 0.3s ease;

    &Collapse {
      display: none;
      opacity: 0;
      transform: translateX(-100%);

      &--shown {
        display: flex;
        opacity: 1;
        transform: translateX(0);
      }
    }

    &Expand {
      left: 0;
      opacity: 1;

      &--hidden {
        opacity: 0;
        transform: translateX(100%);
      }
    }
  }

  &__formWrapper {
    opacity: 0;
    padding: 0;
    transform: translateX(50%);
    transition: transform 0.3s ease, opacity 0.3s ease;

    &--expanded {
      display: block;
      opacity: 1;
      transform: translateX(0);
    }
  }

  &__header {
    margin-bottom: 8px;
  }

  &__lead {
    margin-bottom: auto;
  }
}

@include mq($until: tablet) {

  .cultureSaleCard {

    &--expanded {
      width: 100%;

      .cultureSaleCard__wrapper {
        min-height: unset;
        transition: height 0.3s ease;
        width: 100%;
        will-change: height;
      }

      .cultureSaleCard__footer {
        display: none;
      }
    }

    &__card {
      flex-direction: column;
      gap: 16px;
      height: 100%;
      padding: 24px 16px;
    }

    &__wrapper {
      height: 100%;
    }
  }
}
