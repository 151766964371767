@use "sass:math"; @import "abstract";
.indexLoans {

  &__financingConsultingFormWrapper > div {
    background-color: $color-base-primary;
    margin-bottom: 32px;
  }

  &__banner {
    margin-bottom: 32px;
  }
}
