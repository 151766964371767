@use "sass:math"; @import "abstract";
.inputsSearchWrapper {
  display: flex;
  position: relative;
  width: 100%;

  & .input-wrapper__input {
    border: 2px solid $color-base-accent;
  }

  &__control {
    bottom: 0;
    display: flex;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 15;

    &--reset {
      align-items: center;
      display: flex;
      margin-right: 10px;
    }

    &--submit {
      background-color: $color-base-accent;
      border-radius: 0;
      height: 100%;
      min-height: unset;
    }
  }

  &__button {

    &--desktop {
      display: flex;
    }

    &--mobile {
      display: none;
    }
  }

  input {
    padding-right: 156px;
  }
}

@include mq($until: tablet) {

  .inputsSearchWrapper {
    border: 2px solid $color-base-accent;

    &__button {

      &--desktop {
        display: none;
      }

      &--mobile {
        display: flex;
        max-width: 72px;
      }
    }

    input {
      padding-right: 60px;
    }
  }
}
