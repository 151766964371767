@use "sass:math"; @import "abstract";
.catalogHeader {

  &__queriesWrapper {
    margin-bottom: 32px;
    margin-top: 32px;
  }
}

@include mq($until: tablet) {

  .catalogHeader {

    &__queriesWrapper {
      margin-top: 16px;
    }
  }
}
