@use "sass:math"; @import "abstract";
.financingConsultingForm {
  align-self: start;
  display: grid;
  gap: 24px;
  grid-area: consulting_form;
  margin-top: 40px;
  padding: 0 56px 40px;
}

@include mq($until: desktop) {

  .financingConsultingForm {
    padding: 8px 32px 40px;

    &__form {
      margin-top: 24px;
    }
  }
}

@include mq($until: tablet) {

  .financingConsultingForm {
    padding: 0 16px 16px;
  }
}
