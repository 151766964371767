@use "sass:math"; @import "abstract";
$box-shadow-inset: 16px;

.keen-slider.productsCatalogSlider__slider {
  overflow: visible;
}

.productsCatalogSlider {
  display: flex;
  position: relative;

  &__container {
    display: flex;
    overflow-x: clip;
    padding: 0 $box-shadow-inset;
    width: 100%;
  }

  &:has(&__skeletonSlide) {
    display: flex;
    gap: 16px;
    left: 0;
    overflow: hidden;
    width: 100%;
  }

  &__skeletonSlide {
    height: 318px;
    margin-top: $box-shadow-inset;
    width: 284px !important;
  }

  &__productCard {
    background-color: $color-base-primary;
    border: 1px solid $color-control-bg-primary;
    box-shadow: 0 0 20px rgba(46, 32, 50, 0.1);
    flex-shrink: 0;
    transition: box-shadow 250ms $sharp, border-color 400ms ease;
    width: 284px;
    will-change: box-shadow, border-color;

    &:hover {
      border: 1px solid $color-control-border-primary;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0);
    }

    &--unstyled {
      display: flex;
      flex: 1 1 100%;
      height: 100%;
      min-height: 504px;
      width: 284px;
    }
  }

  &__controlArrows {
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    z-index: 2;
  }

  &__controlArrowItem {
    box-shadow: 0 0 20px 0 rgba(46, 32, 50, 0.1);
    position: absolute;
    top: 50%;
    z-index: 3;

    &-right {
      right: 0;
      transform: translate(50%, -50%);
    }

    &-left {
      left: 0;
      transform: translate(-50%, -50%);
    }
  }

  &__slider {
    list-style: none;
    z-index: 2;
  }
}

@include mq($until: tablet) {

  .productsCatalogSlider {

    &__controlArrowItem {
      display: none;
    }
  }
}
