@use "sass:math"; @import "abstract";
.productsCatalog {

  &__catalog {
    margin-bottom: 32px;
  }
}

@include mq($until: tablet) {

  .productsCatalog {

    &__heading {
      margin-bottom: 16px;
    }

    &__catalog {
      margin-bottom: 24px;
      padding-top: 0;
    }
  }
}
