@use "sass:math"; @import "abstract";
.loanBanner {

  &__slide {
    aspect-ratio: 634 / 105;
    width: 100%;
  }
}

@include mq($until: tablet) {

  .loanBanner {
    align-items: center;
    display: flex;
    justify-content: center;

    &__slide {
      aspect-ratio: unset;
      height: 220px;
      text-align: center;
      width: 328px;
    }
  }
}
