@use "sass:math"; @import "abstract";
.sliderBanner {

  &__slide {
    aspect-ratio: 1268 / 405;
    cursor: pointer;
    max-width: 100%;
    min-width: 100%;
    width: 100%;
  }

  &__image {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  &__link {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }
}

@include mq($until: 479px) {

  .sliderBanner {

    &__slide {
      aspect-ratio: 7 / 8;
      max-width: 280px;
      min-width: 280px;
      width: 280px;
    }
  }
}
