@use "sass:math"; @import "abstract";
.servicesCard {
  background-color: $color-control-bg-secondary;
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: auto 100%;
  flex: 0 1 412px;
  max-height: 197px;
  min-height: 176px;
  overflow: hidden;
  padding: 24px 32px;
  position: relative;

  &__link {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;

    &::before {
      bottom: 0;
      content: '';
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  &__icon {
    vertical-align: middle;
  }

  &__header {
    margin-bottom: 8px;
    max-width: 70%;
  }

  &__subheader {
    max-width: 70%;
  }
}

@include mq($until: tablet) {

  .servicesCard {
    max-width: unset;
    min-height: unset;
    padding: 16px;
  }
}
