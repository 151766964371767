@use "sass:math"; @import "abstract";
.outputsBanners {

  &__slide {
    aspect-ratio: 634 / 105;
    max-height: 210px;
    min-width: 100%;
  }
}

@include mq($until: tablet) {

  .outputsBanners {

    &__slide {
      aspect-ratio: 82 / 55;
      height: 220px;
      max-height: unset;
      text-align: center;
    }
  }
}
