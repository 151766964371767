@use "sass:math"; @import "abstract";
.partnersSlider {
  position: relative;

  &__item {
    align-items: center;
    display: flex;
    height: 140px;
    justify-content: center;
    width: 165px;
  }

  &__controlArrowItem {
    box-shadow: 0 0 20px 0 rgba(46, 32, 50, 0.1);
    position: absolute;
    top: 50%;
    z-index: 1;

    &--right {
      right: 0;
      transform: translate(50%, -50%);
    }

    &--left {
      left: 0;
      transform: translate(-50%, -50%);
    }
  }
}

@include mq($until: tablet) {

  .partnersSlider {

    &__controlArrowItem {
      display: none;
    }
  }
}
