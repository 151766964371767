@use "sass:math"; @import "abstract";
@include mq($until: tablet) {


  .pageBlock {
    
    &--noRight {
      margin-right: 0;
      padding-right: 0;
    }
  }
}
