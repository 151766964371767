@use "sass:math"; @import "abstract";
.productCategories {
  background-color: $color-base-secondary;
  border-radius: 8px;
  display: flex;
  gap: 8px;
  margin-bottom: 32px;
  padding: 12px;
  position: relative;

  .categorySlider {
    list-style: none;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    padding: 0;
    position: relative;
    width: 100%;
    z-index: 1;
  }

  &__card {
    align-items: center;
    background-color: $color-status-info-neutral;
    cursor: pointer;
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 8px;
    justify-content: space-between;
    min-height: 133px;
    min-width: 196px;
    padding: 16px 24px;
    transition: background-color 0.25s cubic-bezier(0.4, 0, 0.6, 1);
    will-change: background-color;

    &:not(&:last-child) {
      margin-right: 8px;
    }

    &--selected {
      background-color: $color-control-bg-accent;
    }

    &Quantity {
      color: $color-text-secondary;
      text-align: center;
    }

    &Category {
      align-items: center;
      display: flex;
      height: 100%;
      text-align: center;
      word-break: break-word;
    }

    &--selected &Category,
    &--selected &Quantity {
      color: $color-text-inverted;
    }

    &:hover {
      background-color: $color-control-bg-accent;
    }

    &:hover &Category,
    &:hover &Quantity {
      color: $color-text-inverted;
    }
  }

  &__controlArrowItem {
    box-shadow: 0 0 20px 0 rgba(46, 32, 50, 0.1);
    position: absolute;
    top: 50%;
    z-index: 3;

    &-right {
      right: 0;
      transform: translate(50%, -50%);
    }

    &-left {
      left: 0;
      transform: translate(-50%, -50%);
    }
  }
}

@include mq($until: tablet) {

  .productCategories {
    margin-bottom: 16px;
    padding-right: 0;

    &__card {
      gap: 4px;
      min-height: auto;
      min-width: fit-content;
      padding: 12px;
    }
  }
}
