@use "sass:math"; @import "abstract";
.pricePredictionWidget {
  align-self: flex-start;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 1px;
  padding: 12px 16px 24px;
  width: 296px;

  &__textBlock + &__textBlock {
    margin-top: 16px;
  }

  &__textBlock {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__priceHeader {
    align-items: center;
    display: flex;
  }

  &__headerText {
    color: $color-text-secondary;
  }

  &__notice {
    color: $color-text-secondary;
    margin-top: 4px;
  }

  &__noPrice {
    color: $color-text-secondary;
    margin-top: 16px;
  }
}

@include mq($from: desktop) {

  .pricePredictionWidget {
    margin-top: 40px;
  }
}

@include mq($until: desktop) {

  .pricePredictionWidget {
    height: unset;
    width: 100%;
  }
}

@include mq($until: tablet) {

  .pricePredictionWidget {
    margin-bottom: 8px;
    padding: 12px 16px;

    &__textBlock {
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
    }

    &__notice {
      display: none;
    }
  }
}
