@use "sass:math"; @import "abstract";
.becomePartners {
  display: flex;
  flex-wrap: nowrap;
  gap: 24px;

  &__slide {
    aspect-ratio: 311 / 129;
    cursor: pointer;
  }
}

@include mq($until: tablet) {

  .becomePartners {
    flex-wrap: wrap;
    justify-content: center;

    &__slide {
      aspect-ratio: 82 / 55;
      max-width: 328px;
    }
  }
}
