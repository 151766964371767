@use "sass:math"; @import "abstract";
.indexSaleRequestForm {
  display: flex;
  flex: 1 1 auto;
  gap: 32px;
  height: 100%;

  &__inputs {
    display: flex;
    flex: 1 1 auto;
    justify-content: flex-end;
  }

  &__hidden {
    display: none;
  }
}

@include mq($until: desktop) {

  .indexSaleRequestForm {
    flex-direction: column;

    &__inputs {
      opacity: 0;
      order: 2;
      overflow: hidden;
      transform: translateY(-50%);
      transition: transform 0.3s ease, opacity 0.4s ease;

      &--expanded {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
}

@include mq($until: tablet) {

  .indexSaleRequestForm {
    gap: 16px;
  }
}
