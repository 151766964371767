@use "sass:math"; @import "abstract";
.sectionHeading {
  margin-bottom: 32px;
  padding-bottom: 10px;
  padding-top: 10px;

  &__icon {
    vertical-align: middle;
  }

  &__headerWrapper {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  &__offers {
    color: $color-text-tertiary;
  }

  &__subheader {
    color: $color-text-secondary;
    max-width: 50%;
  }
}

@include mq($until: tablet) {

  .sectionHeading {
    margin-bottom: 16px;

    &__headerWrapper {
      align-items: flex-start;
      flex-direction: column;
      gap: 8px;
      margin-bottom: 8px;
    }

    &__subheader {
      max-width: unset;
    }
  }
}
