@use "sass:math"; @import "abstract";
.getAllProducts {
  align-items: center;
  background-color: $color-control-bg-primary;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
  justify-content: center;
  position: relative;

  &__clickable {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    // position: relative;
  }

  &:hover &__icon {
    background-color: $color-control-bg-accent;
    color: $color-text-inverted;
  }

  &__icon {
    align-items: center;
    background-color: $color-control-bg-secondary;
    border-radius: 50%;
    display: flex;
    height: 48px;
    justify-content: center;
    transition: background-color 0.3s ease, color 0.3s ease;
    width: 48px;
    will-change: background-color, color;
  }

  &__text {
    max-width: 200px;
    text-align: center;
  }
}
